import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'
import {AbaxElectricalTestimonial} from '../components/testimonial';
import AppStoreIcons from '../components/app-store-icons';

import FreeTrialButton from '../components/free-trial-button';

import reportSrc from '../images/report.svg';
import cleanUpSrc from '../images/clean-up.svg';
import geofenceSrc from '../images/geofence.png';
import mapMultipleSrc from '../images/map-multiple.svg';
import clockInSrc from '../images/clockin.png';
import wallCalendarSrc from '../images/leave-calendar.svg';
import accountancyIntegrationsSrc from '../images/accountancy-integrations.svg';

const TimesheetAppCleaningCompanies = () => (
  <Layout>
    <SEO
    	title="Timesheet App for Cleaning Companies"
    	description="Get rid of paper timesheets in your cleaning company. Simple employee timesheets that run on a mobile app."
    />
    <Navbar />
    <div className="bg-gray-50">
	  	<div className="relative bg-white overflow-hidden">
		  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
		    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
		      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
		        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
		          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
		            Simple timesheet app for
		            <br />
		            <span className="text-highlight-600">cleaning companies</span>
		          </h2>
		          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
		             Switch from messy paper timesheets to a clean timesheet platform today!
		          </p>
		          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
		             You keep businesses and homes clean and tidy, let us handle the timesheets.
		          </p>

		          <FreeTrialButton />
		        </div>
		        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
		          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
		            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
		              <img className="w-full" src={cleanUpSrc} alt="Man cleaning up" />
		            </button>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
		<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
		  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
			     <RightFeature
			    	sectionTitle="Add the TimeKeeper app to your cleaning kit"
			    	title="Mobile or Tablet based Timesheets"
			    	body={
			    		<div>
				    		<FeaturePararaph>
					          Why use paper timesheets when everyone has a device in their back pocket?
					        </FeaturePararaph>
					        <FeaturePararaph>
					          TimeKeeper runs on your everyday mobiles and tablets allowing you and your staff to clock in and out when they need to.

					          Additionally, you can utilise advanced features like clocking in with a photo and facial recognition checks.
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={clockInSrc}
			    	imgAlt="Showing Man Using Mobile or Tablet to Clock In" />


			    <LeftFeature
			    	sectionTitle="Perfect for cleaners on client sites"
			    	title="Geofenced GPS Clock Ins"
			    	body={
			    		<div>
				    		<FeaturePararaph>
					          Your cleaning business likely serves many different clients, across a wide range of locations.
					        </FeaturePararaph>
					        <FeaturePararaph>
					          Mobile apps allow your employees to clock in remotely at as many locations as you choose, all over the world - and you can set geofence boundaries, to ensure they only clock in when they arrive at a job.
					        </FeaturePararaph>
					        <AppStoreIcons />
					    </div>
			    	}
			    	imgSrc={geofenceSrc}
			    	imgAlt="GPS Location on Mobile App Clock In"
			     />


			     <RightFeature
			    	sectionTitle="Track different jobs for different clients"
			    	title="Effortlessly track time on jobs and clients"
			    	body={
			    		<div>
				    		<FeaturePararaph>
					        	Get more from your time tracking by asking your employees to select a job before clocking in, and link multiple jobs to one client.
					        </FeaturePararaph>
					        <FeaturePararaph>
					          We'll automatically track how many hours your employees spend on each particular project/clients, which you can calculate anytime with an in-built report.
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={reportSrc}
			    	imgAlt="Tracking time on jobs report"
			    />
			    <LeftFeature
			    	sectionTitle="Live Who's In"
			    	title="See who's clocked in, in real time"
			    	body={
			    		<div>
				    		<FeaturePararaph>
					          When managing a remote team across multiple client sites, much of your time is spent on back-and-forth phone calls to ensure your employees have turned up to work on time and are where they need to be.
					        </FeaturePararaph>
					        <FeaturePararaph>
					          TimeKeeper keeps a live list of each worker's latest clock-in location, meaning you don't have to call them to check if an employee is in. You can check this anytime via your mobile or the web portal.
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={mapMultipleSrc}
			    	imgAlt="Markers of who is in on each building site"
			    />
			    <RightFeature
			    	sectionTitle="Managing and Tracking Employee Leave"
			    	title="Managing Time In + Time On Leave"
			    	includeShadow={true}
			    	body={
			    		<div>
				    		<FeaturePararaph>
					          If you have a large team or lots of staff, it can be easy to lose track of who is on holiday and when. Even things like how much holiday each employee has left is time-consuming to track!
					        </FeaturePararaph>
					        <FeaturePararaph>
					          Here at TimeKeeper we can keep all this managed for you as well at no extra cost. Employees can make leave requests via the app that can be approved by their relevant line manager. Additionally, you can plan your team availability with our digital wall calendar.
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={wallCalendarSrc}
			    	imgAlt="Viewing staff absences on a wall calendar"/>
			    <LeftFeature
			    	sectionTitle="Perfect Payroll Integrations"
			    	title="Integrate into your payroll process"
			    	body={
			    		<div>
				    		<FeaturePararaph>
					          We'll do the heavy lifting in taking your timesheets to payroll. We can calculate the right amount of regular and overtime to give each employee.
					        </FeaturePararaph>
					        <FeaturePararaph>
					          Then sending timesheets data from TimeKeeper to your payroll provider is a breeze! Current integCorations include Xero, Sage, MoneySoft and BrightPay as well as export to CSV and Excel.
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={accountancyIntegrationsSrc}
			    	imgAlt="Payroll integration with Xero, Sage, MoneySoft and BrightPay"
			    />
			</div>
			<AbaxElectricalTestimonial />
			<FreeTrial includeDemo={true} part1={<span>Tidy up your timesheets <br /> in your <span className="text-highlight-600">cleaning business</span> today!</span>} />
		</div>
	</div>
	<Footer/>
  </Layout>
)

export default TimesheetAppCleaningCompanies;
